<template>
  <LoadingBar v-if="isLoading"></LoadingBar>
  <div class="flex flex-col h-full">
    <page-header>
      <page-title title="Usage Statistics">
        <button
          @click="openExport"
          class="inline-flex items-center focus:outline-none"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="w-5 h-5"
          >
            <path
              fill-rule="evenodd"
              d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clip-rule="evenodd"
            /></svg
          ><span>Export Statistics</span>
        </button>
      </page-title>
    </page-header>

    <div class="vendor-container">
      <div class="vendor-content">
        <div
          class="
            inline-block
            min-w-full
            overflow-hidden
            align-middle
            border-b border-gray-200
            sm:rounded-lg
          "
        >
          <div
            class="
              px-3
              py-3
              mt-5
              bg-white
              border-b border-gray-200
              mt-
              sm:rounded-lg
            "
          >
            <div class="flex w-full">
              <ul class="flex w-full border-b list-reset">
                <li
                  :class="[
                    scanActiveTab === 1
                      ? '-mb-px mr-1 w- border-l border-t border-r rounded-t'
                      : 'mr-1',
                  ]"
                >
                  <a
                    class="inline-block px-4 py-2 text-sm bg-white"
                    :class="scanActiveTab === 1 ? 'bg-gray-200' : 'bg-white'"
                    href="#"
                    @click="scanTimeframe('today')"
                    >Today</a
                  >
                </li>
                <li
                  :class="[
                    scanActiveTab === 2
                      ? '-mb-px mr-1 w- border-l border-t border-r rounded-t'
                      : 'mr-1',
                  ]"
                >
                  <a
                    class="
                      inline-block
                      px-4
                      py-2
                      text-sm
                      bg-white
                      text-blue
                      hover:text-blue-darker
                    "
                    :class="scanActiveTab === 2 ? 'bg-gray-200' : 'bg-white'"
                    href="#"
                    @click="scanTimeframe('last_7days')"
                    >Last 7 Days</a
                  >
                </li>
                <li
                  :class="[
                    scanActiveTab === 3
                      ? '-mb-px mr-1 w- border-l border-t border-r rounded-t'
                      : 'mr-1',
                  ]"
                >
                  <a
                    class="
                      inline-block
                      px-4
                      py-2
                      text-sm
                      bg-white
                      text-blue
                      hover:text-blue-darker
                    "
                    :class="scanActiveTab === 3 ? 'bg-gray-200' : 'bg-white'"
                    href="#"
                    @click="scanTimeframe('last_30days')"
                    >Last 30 Days</a
                  >
                </li>
                <li
                  :class="[
                    scanActiveTab === 4
                      ? '-mb-px mr-1 w- border-l border-t border-r rounded-t'
                      : 'mr-1',
                  ]"
                >
                  <a
                    class="
                      inline-block
                      px-4
                      py-2
                      text-sm
                      bg-white
                      text-blue
                      hover:text-blue-darker
                    "
                    :class="scanActiveTab === 4 ? 'bg-gray-200' : 'bg-white'"
                    href="#"
                    @click="scanTimeframe('last_90days')"
                    >Last 90 Days</a
                  >
                </li>
                <li
                  :class="[
                    scanActiveTab === 5
                      ? '-mb-px mr-1 w- border-l border-t border-r rounded-t'
                      : 'mr-1',
                  ]"
                >
                  <a
                    class="
                      inline-block
                      px-4
                      py-2
                      text-sm
                      bg-white
                      text-grey-light
                    "
                    :class="scanActiveTab === 5 ? 'bg-gray-200' : 'bg-white'"
                    href="#"
                    @click="scanTimeframe('this_year')"
                    >This Year</a
                  >
                </li>
                <li
                  :class="[
                    scanActiveTab === 6
                      ? '-mb-px mr-1 w- border-l border-t border-r rounded-t'
                      : 'mr-1',
                  ]"
                >
                  <a
                    class="
                      inline-block
                      px-4
                      py-2
                      text-sm
                      bg-white
                      text-grey-light
                    "
                    :class="scanActiveTab === 6 ? 'bg-gray-200' : 'bg-white'"
                    href="#"
                    @click="scanTimeframe('all_stats')"
                    >All Time</a
                  >
                </li>
              </ul>
            </div>
            <div class="flex content-start">
              <span class="pt-2 ml-2 text-gray-500"> Average Scans</span>
            </div>
            <div class="flex content-start w-full">
              <span class="pt-2 ml-2 font-bold text-gray-500">
                {{ aveScan }} {{ aveScanText }}
              </span>
            </div>
            <div class="relative pt-1">
              <div
                class="
                  flex
                  h-2
                  mb-4
                  overflow-hidden
                  text-xs
                  bg-gray-200
                  rounded
                "
              >
                <div
                  :style="{ width: scanPercentage + '%' }"
                  class="
                    flex flex-col
                    justify-center
                    text-center text-white
                    shadow-none
                    whitespace-nowrap
                    bg-qgo-primary-bg
                  "
                ></div>
              </div>
            </div>
            <div class="flex w-full mt-5">
              <chart-index :chartLabels="chartLabels" :chartData="scanData" />
            </div>
            <div class="flex content-start">
              <span class="pt-2 ml-2 text-gray-500"> CUSTOMER RETENTION</span>
            </div>
            <div class="relative pt-3">
              <div
                class="flex h-2 mb-4 overflow-hidden text-xs bg-red-500 rounded"
              >
                <div
                  :style="{ width: newCustomerPercentage + '%' }"
                  class="
                    flex flex-col
                    justify-center
                    text-center text-white
                    shadow-none
                    whitespace-nowrap
                    bg-qgo-primary-bg
                  "
                ></div>
              </div>
            </div>
            <div class="flex flex-row justify-between">
              <div class="flex flex-col">
                <div class="flex">
                  <span class="pt-2 ml-2 text-qgo-primary-bg"
                    >New Customers</span
                  >
                </div>
                <div class="flex">
                  <h2 class="pt-1 ml-2 font-bold">
                    {{ newCustomerPercentage }}%
                  </h2>
                  <h2 class="pt-1 ml-2 text-qgo-primary-bg">
                    <!-- Up 5% previous days -->
                  </h2>
                </div>
              </div>
              <div class="flex flex-col">
                <div class="flex">
                  <span class="pt-2 ml-2 text-red-500"
                    >Returning Customers</span
                  >
                </div>
                <div class="flex">
                  <h2 class="pt-1 ml-2 font-bold">
                    {{ returningCustomerPercentage }}%
                  </h2>
                  <h2 class="pt-1 ml-2 text-red-500">
                    <!-- Down 5% previous days -->
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <!-- wait time chart -->
          <div class="flex mt-10 ml-2">
            <span>Average Order Wait Time</span>
          </div>
          <div
            class="
              px-3
              py-3
              mt-2
              bg-white
              border-b border-gray-200
              mt-
              sm:rounded-lg
            "
          >
            <div class="flex content-start w-full my-4 ml-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21.42"
                height="36.715"
                viewBox="0 0 26.42 41.715"
              >
                <g id="hourglass" transform="translate(-93.867)">
                  <g
                    id="Group_245"
                    data-name="Group 245"
                    transform="translate(93.867)"
                  >
                    <g
                      id="Group_244"
                      data-name="Group 244"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_219"
                        data-name="Path 219"
                        d="M118.2,0H95.953a2.086,2.086,0,1,0,0,4.172H118.2A2.086,2.086,0,1,0,118.2,0Zm0,2.781H95.953a.7.7,0,0,1,0-1.39H118.2a.7.7,0,1,1,0,1.39Z"
                        transform="translate(-93.867)"
                        fill="#009A74"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_247"
                    data-name="Group 247"
                    transform="translate(93.867 37.544)"
                  >
                    <g
                      id="Group_246"
                      data-name="Group 246"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_220"
                        data-name="Path 220"
                        d="M118.2,460.8H95.953a2.086,2.086,0,1,0,0,4.172H118.2a2.086,2.086,0,1,0,0-4.172Zm0,2.781H95.953a.7.7,0,1,1,0-1.39H118.2a.7.7,0,1,1,0,1.39Z"
                        transform="translate(-93.867 -460.8)"
                        fill="#009A74"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_249"
                    data-name="Group 249"
                    transform="translate(96.648 2.781)"
                  >
                    <g
                      id="Group_248"
                      data-name="Group 248"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_221"
                        data-name="Path 221"
                        d="M143.383,49.63a18.568,18.568,0,0,0,5.474-13.216V34.829a.7.7,0,0,0-.7-.7H128.7a.7.7,0,0,0-.7.7v1.584a18.569,18.569,0,0,0,5.474,13.216l.7.7a2.661,2.661,0,0,1,0,3.764l-.7.7A18.567,18.567,0,0,0,128,68.007v1.584a.7.7,0,0,0,.7.7h19.467a.7.7,0,0,0,.7-.7V68.007a18.568,18.568,0,0,0-5.474-13.216l-.7-.7a2.662,2.662,0,0,1,0-3.764Zm-1.682-.284a4.052,4.052,0,0,0,0,5.73l.7.7a17.187,17.187,0,0,1,5.067,12.233V68.9H129.391v-.889a17.188,17.188,0,0,1,5.066-12.233l.7-.7a4.051,4.051,0,0,0,0-5.73l-.7-.7a17.187,17.187,0,0,1-5.067-12.233v-.889h18.077v.889A17.187,17.187,0,0,1,142.4,48.647Z"
                        transform="translate(-128 -34.134)"
                        fill="#009A74"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_251"
                    data-name="Group 251"
                    transform="translate(101.515 9.318)"
                  >
                    <g id="Group_250" data-name="Group 250">
                      <path
                        id="Path_222"
                        data-name="Path 222"
                        d="M199.178,116.758a.989.989,0,0,0-1.009-.589c-3.613,0-5.222-.774-5.86-1.235a2.972,2.972,0,0,0-2.734-.4,2.741,2.741,0,0,0-1.758,1.888,2.269,2.269,0,0,0-.074.382.734.734,0,0,0,.087.405,15.456,15.456,0,0,0,2.485,3.455l.7.7a5.4,5.4,0,0,1,1.594,3.848.7.7,0,0,0,1.39,0,5.407,5.407,0,0,1,1.593-3.848l.7-.7c.172-.171.4-.382.642-.613C198.73,118.356,199.485,117.534,199.178,116.758Zm-3.2,2.276c-.257.243-.492.464-.672.643l-.7.7a6.83,6.83,0,0,0-1.306,1.82,6.827,6.827,0,0,0-1.306-1.82l-.7-.7a13.336,13.336,0,0,1-2.129-2.929,1.359,1.359,0,0,1,.869-.907,1.582,1.582,0,0,1,1.455.218c.886.641,2.632,1.4,5.978,1.49C197.042,118.033,196.367,118.668,195.979,119.033Z"
                        transform="translate(-187.741 -114.362)"
                        fill="#009A74"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_253"
                    data-name="Group 253"
                    transform="translate(99.475 29.201)"
                  >
                    <g
                      id="Group_252"
                      data-name="Group 252"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_223"
                        data-name="Path 223"
                        d="M177.9,364.6a6.025,6.025,0,0,0-2.124-4.274c-.141-.147-.288-.3-.439-.46a4.652,4.652,0,0,0-3.385-1.468h-3.3a4.654,4.654,0,0,0-3.385,1.468c-.151.161-.3.313-.439.46A6.022,6.022,0,0,0,162.7,364.6a.694.694,0,0,0,.693.749H177.2a.7.7,0,0,0,.693-.75Zm-13.715-.641a4.832,4.832,0,0,1,1.64-2.668c.146-.152.3-.309.453-.476a3.254,3.254,0,0,1,2.369-1.028h3.3a3.259,3.259,0,0,1,2.37,1.027c.156.168.308.325.455.477a4.84,4.84,0,0,1,1.639,2.668Z"
                        transform="translate(-162.696 -358.4)"
                        fill="#009A74"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_255"
                    data-name="Group 255"
                    transform="translate(106.361 22.248)"
                  >
                    <g
                      id="Group_254"
                      data-name="Group 254"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_224"
                        data-name="Path 224"
                        d="M247.906,273.067a.7.7,0,0,0-.7.7v.7a.7.7,0,0,0,1.39,0v-.7A.7.7,0,0,0,247.906,273.067Z"
                        transform="translate(-247.211 -273.067)"
                        fill="#009A74"
                      />
                    </g>
                  </g>
                  <g
                    id="Group_257"
                    data-name="Group 257"
                    transform="translate(106.361 25.724)"
                  >
                    <g
                      id="Group_256"
                      data-name="Group 256"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_225"
                        data-name="Path 225"
                        d="M247.906,315.733a.7.7,0,0,0-.7.7v.7a.7.7,0,0,0,1.39,0v-.7A.7.7,0,0,0,247.906,315.733Z"
                        transform="translate(-247.211 -315.733)"
                        fill="#009A74"
                      />
                    </g>
                  </g>
                </g>
              </svg>
              <span class="flex pt-2 ml-2 text-gray-500"
                >On average, customers are waiting {{ minutes }} minutes for
                their order.</span
              >
            </div>
            <div class="flex content-start">
              <span class="pt-2 ml-2 text-gray-500"> Wait Time Insights</span>
            </div>
            <div class="flex w-full">
              <ul class="flex w-full border-b list-reset">
                <li
                  :class="[
                    waitActiveTab === 1
                      ? '-mb-px mr-1 w- border-l border-t border-r rounded-t'
                      : 'mr-1',
                  ]"
                >
                  <a
                    class="
                      inline-block
                      px-4
                      py-2
                      text-sm
                      bg-white
                      cursor-pointer
                    "
                    :class="waitActiveTab === 1 ? 'bg-gray-200' : 'bg-white'"
                    @click="waitTimeframe('today')"
                    >Today</a
                  >
                </li>
                <li
                  :class="[
                    waitActiveTab === 2
                      ? '-mb-px mr-1 w- border-l border-t border-r rounded-t'
                      : 'mr-1',
                  ]"
                >
                  <a
                    class="
                      inline-block
                      px-4
                      py-2
                      text-sm
                      bg-white
                      text-blue
                      hover:text-blue-darker
                      cursor-pointer
                    "
                    :class="waitActiveTab === 2 ? 'bg-gray-200' : 'bg-white'"
                    @click="waitTimeframe('last_7days')"
                    >Last 7 Days</a
                  >
                </li>
                <li
                  :class="[
                    waitActiveTab === 3
                      ? '-mb-px mr-1 w- border-l border-t border-r rounded-t'
                      : 'mr-1',
                  ]"
                >
                  <a
                    class="
                      inline-block
                      px-4
                      py-2
                      text-sm
                      bg-white
                      text-blue
                      hover:text-blue-darker
                      cursor-pointer
                    "
                    :class="waitActiveTab === 3 ? 'bg-gray-200' : 'bg-white'"
                    @click="waitTimeframe('last_30days')"
                    >Last 30 Days</a
                  >
                </li>
                <li
                  :class="[
                    waitActiveTab === 4
                      ? '-mb-px mr-1 w- border-l border-t border-r rounded-t'
                      : 'mr-1',
                  ]"
                >
                  <a
                    class="
                      inline-block
                      px-4
                      py-2
                      text-sm
                      bg-white
                      text-blue
                      hover:text-blue-darker
                      cursor-pointer
                    "
                    :class="waitActiveTab === 4 ? 'bg-gray-200' : 'bg-white'"
                    @click="waitTimeframe('last_90days')"
                    >Last 90 Days</a
                  >
                </li>
                <li
                  :class="[
                    waitActiveTab === 5
                      ? '-mb-px mr-1 w- border-l border-t border-r rounded-t'
                      : 'mr-1',
                  ]"
                >
                  <a
                    class="
                      inline-block
                      px-4
                      py-2
                      text-sm
                      bg-white
                      text-grey-light
                      cursor-pointer
                    "
                    :class="waitActiveTab === 5 ? 'bg-gray-200' : 'bg-white'"
                    @click="waitTimeframe('this_year')"
                    >This Year</a
                  >
                </li>
                <li
                  :class="[
                    waitActiveTab === 6
                      ? '-mb-px mr-1 w- border-l border-t border-r rounded-t'
                      : 'mr-1',
                  ]"
                >
                  <a
                    class="
                      inline-block
                      px-4
                      py-2
                      text-sm
                      bg-white
                      text-grey-light
                      cursor-pointer
                    "
                    :class="waitActiveTab === 6 ? 'bg-gray-200' : 'bg-white'"
                    @click="waitTimeframe('all_stats')"
                    >All Time</a
                  >
                </li>
              </ul>
            </div>
            <div class="flex w-full mt-5">
              <chart-time
                :chartLabels="waitTimeChartLabels"
                :chartData="waitTimeData"
              />
            </div>
          </div>
        </div>

        <!-- modal for export -->
        <div v-if="exportModal">
          <modal-view>
            <ExportStatistics></ExportStatistics>
          </modal-view>
        </div>
      </div>
    </div>

    <page-footer>
      <footer-nav></footer-nav>
    </page-footer>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import PageHeader from "@/components/pages/Header.vue";
import PageTitle from "@/views/Vendor/PageTitle.vue";
import PageFooter from "@/components/pages/Footer.vue";
import FooterNav from "@/views/Vendor/FooterNav.vue";
import LoadingBar from "@/components/loader/Loader.vue";
import ModalView from "@/components/Modal.vue";
import ExportStatistics from "@/views/Vendor/Modal/ExportStatistics.vue";
import ChartIndex from "@/components/chart/Index";
import ChartTime from "@/components/chart/WaitTimeChart";

export default defineComponent({
  setup() {
    return {};
  },
  components: {
    PageHeader,
    PageTitle,
    PageFooter,
    FooterNav,
    LoadingBar,
    ModalView,
    ExportStatistics,
    ChartIndex,
    ChartTime,
  },
  data() {
    return {};
  },
  methods: {
    scanTimeframe(tab) {
      this.$store.dispatch("statistics/fetchStatisticsScanData", tab).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
          this.$swal.fire({
            icon: "error",
            title: "Something went wrong while fetching scan statistics!",
            timer: 5000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
        }
      );
    },
    waitTimeframe(tab) {
      this.$store.dispatch("statistics/fetchStatisticsWaitTimeData", tab).then(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log(error);
          this.$swal.fire({
            icon: "error",
            title: "Something went wrong while fetching wait time statistics!",
            timer: 5000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
        }
      );
    },
    openExport() {
      this.$store.commit("statistics/setExportModal", true);
    },
  },
  computed: {
    exportModal() {
      return this.$store.getters["statistics/exportModal"];
    },
    aveScan() {
      return this.$store.getters["statistics/aveScan"];
    },
    aveScanText() {
      return this.$store.getters["statistics/aveScanText"];
    },
    newCustomerPercentage() {
      return this.$store.getters["statistics/newCustomerPercentage"];
    },
    returningCustomerPercentage() {
      return this.$store.getters["statistics/returnCustomerPercentage"];
    },
    scanPercentage() {
      return this.$store.getters["statistics/scanPercentage"];
    },
    scanActiveTab() {
      return this.$store.getters["statistics/scanActiveTab"];
    },
    waitActiveTab() {
      return this.$store.getters["statistics/waitActiveTab"];
    },
    chartLabels() {
      return this.$store.getters["statistics/chartLabels"];
    },
    waitTimeChartLabels() {
      return this.$store.getters["statistics/waitTimeChartLabels"];
    },
    scanData() {
      return this.$store.getters["statistics/scanData"];
    },
    waitTimeData() {
      return this.$store.getters["statistics/waitTimeData"];
    },
    minutes() {
      return this.$store.getters["statistics/minutes"];
    },
    isLoading() {
      return this.$store.getters["statistics/isLoading"];
    },
  },
  created() {
    this.scanTimeframe("today");
    this.waitTimeframe("today");
  },
});
</script>
