<template>
  <div
    class="fixed overflow-x-hidden overscroll-y-auto inset-0 flex justify-center items-center z-50 bg-gray-800 bg-opacity-40"
  >
    <div class="relative mx-auto w-auto max-w-2xl float-none">
      <div class="w-full bg-white rounded shadow-2xl">
        <slot />
      </div>
    </div>
  </div>
</template>
